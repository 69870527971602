import React from 'react';
import Router from '@fingo/lib/components/layout/Router';
import { useFingoRoutes, useShowingTyC } from '../../hooks';
import NpsProvider from '../../components/nps/NpsProvider';
import TermsAndConditions from '../../components/onboarding/TermsAndConditions';
import PopUpAlertProvider from './PopUpAlertProvider';

const App = () => {
  const fingoRoutes = useFingoRoutes().filter((route) => !route.disabled);
  const showTyc = useShowingTyC();
  if (showTyc) return <TermsAndConditions />;
  return (
    <Router routes={fingoRoutes} depth={2} />
  );
};

export default () => (
  <NpsProvider>
    <PopUpAlertProvider>
      <App />
    </PopUpAlertProvider>
  </NpsProvider>
);
