import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useLocation } from 'react-router-dom';
import { useGetUser } from '@fingo/lib/hooks';

const HotjarProvider = ({ children }) => {
  const user = useGetUser();
  const isHotjarInitialized = hotjar.initialized();
  // Obtener los parámetros de la URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Verificar si el parámetro UTM campaign está activado
  const utmCampaign = searchParams.get('utm_campaign') || '';
  const isProduction = process.env.NODE_ENV === 'production';
  const loggedInOrUtmCampaign = user || utmCampaign;
  const shouldInitializeHotjar = isProduction
   && (!user?.isSuperuser)
   && loggedInOrUtmCampaign;

  useEffect(() => {
    if (shouldInitializeHotjar && !isHotjarInitialized) {
      hotjar.initialize(2019010, 6);
    }
  }, [shouldInitializeHotjar]);

  useEffect(() => {
    if (isHotjarInitialized && user) {
      hotjar.identify(user.id, {
        companyId: user.selectedCompany?.rut,
        companyName: user.selectedCompany?.name,
        userCompleteName: user.completeName,
      });
    }
  }, [isHotjarInitialized, user]);
  return children;
};

export default HotjarProvider;
