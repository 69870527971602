import React, { useMemo, useRef } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import {
  useIsMobile,
  useSelectedCompany,
  useOffers,
  useHomeCards,
} from '@fingo/lib/hooks';
import BicolorBackgroundBox from '@fingo/lib/components/boxes/BicolorBackgroundBox';
import ExecutiveAssignedInfo from '@fingo/lib/components/text/ExecutiveAssignedInfo';
import usePurchaseOrders from '@fingo/lib/hooks/usePurchaseOrders';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import {
  invoiceHeaders,
  orderingHeaders,
} from '@fingo/lib/constants/home-available-offers-headers';
import { openSiiFormWithoutAutoFill } from '@fingo/lib/helpers/sii-credentials-form-toggle';
import RegistrationProgressBanner from '../../components/banners/RegistrationProgressBanner';
import AlertBanner from '../../components/banners/AlertBanner';
import HomeCard from '../../components/cards/HomeCard';
import HomeAvailableOffers from '../../components/home/HomeAvailableOffers';
import HomeInvoicesReceived from '../../components/home/HomeInvoicesReceived';
import useOrderingPreColumns from '../../components/invoice/useOrderingPreColumns';

const Home = () => {
  const isMobile = useIsMobile();
  const paperRef = useRef(null);
  const selectedCompany = useSelectedCompany();
  const isCompanyRegistrationCompleted = useMemo(
    () => selectedCompany?.hasSiiCredentials
      && selectedCompany?.hasDigitalCertificate
      && selectedCompany?.hasInvoiceProviderCredentials
      && selectedCompany?.hasBankAccount,
    [selectedCompany],
  );

  const {
    offers,
    totalCount: invoicesTotalCount,
    loading: invoiceLoading,
  } = useOffers({
    skip: !selectedCompany,
    variables: {
      companyId: selectedCompany?.id,
      hasValidOffer: true,
    },
  });

  const {
    purchaseOrders,
    purchaseOrdersTotalCount,
    loading: purchaseOrdersLoading,
  } = usePurchaseOrders({
    variables: {
      companyId: selectedCompany?.id,
      inOffer: true,
    },
    skip: !selectedCompany,
  });

  const homeCardsArray = useHomeCards(
    invoicesTotalCount,
    purchaseOrdersTotalCount,
  );
  const addAlert = false;

  const BannerComponent = () => {
    if (!invoiceLoading && !purchaseOrdersLoading) {
      if (offers.length) {
        return (
          <HomeAvailableOffers
            offers={offers}
            columns={useInvoicePreColumns()}
            includeHeaders={invoiceHeaders}
            viewOffersLink="/app/sales/factoring/offers"
            docType="facturas"
          />
        );
      }
      if (purchaseOrders.length) {
        return (
          <HomeAvailableOffers
            offers={purchaseOrders}
            columns={useOrderingPreColumns()}
            includeHeaders={orderingHeaders}
            viewOffersLink="/app/sales/ordering/offer"
            docType="órdenes de compra"
          />
        );
      }
      return <HomeInvoicesReceived />;
    }
    return <CircularProgress />;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          height: '10px',
          background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.main} 60%)`,
          position: 'relative',
        })}
      />
      {!selectedCompany?.hasSiiCredentials && openSiiFormWithoutAutoFill()}
      {!isCompanyRegistrationCompleted && <RegistrationProgressBanner />}
      {addAlert && (
        <AlertBanner isRegisterBannerShown={!isCompanyRegistrationCompleted} />
      )}
      {!isMobile && <ExecutiveAssignedInfo addAlert={addAlert} />}
      <BicolorBackgroundBox
        paddingTop={isMobile ? 2 : 4}
        height={isMobile ? '100%' : ''}
        flexGrow="1"
        px="4%"
        sx={(theme) => ({
          background: isMobile
            ? theme.palette.text.main
            : `linear-gradient(${theme.palette.text.main} 50%, ${theme.palette.background.dark} 30%)`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isMobile ? '' : 'space-between',
        })}
      >
        <Stack
          ref={paperRef}
          borderRadius={4}
          py={2}
          sx={(theme) => ({
            px: 16,
            maxHeight: 450,
            [theme.breakpoints.down('lg')]: {
              px: 10,
              maxHeight: 'inherit',
            },
            [theme.breakpoints.down('md')]: {
              px: 4,
              maxHeight: 'inherit',
            },
            [theme.breakpoints.down('sm')]: {
              px: 0,
              mx: 0,
              width: '100%',
              maxHeight: '100%',
            },
            mx: 'auto',
          })}
          spacing={2}
          width="100%"
        >
          <Typography
            width="100%"
            align="center"
            variant="h1"
            color="background.light"
            sx={{
              fontSize: { xs: 40, md: 25 },
              fontWeight: isMobile ? 700 : '',
              opacity: isMobile ? 0.5 : 1,
            }}
          >
            {isMobile ? 'Bienvenido' : '¡Bienvenido a Fingo!'}
          </Typography>
          <Typography
            width="100%"
            align="center"
            variant="h2"
            fontWeight={isMobile ? '' : 700}
            color="background.light"
            pb={1}
          >
            ¿Qué quieres hacer hoy?
          </Typography>
          <Stack
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
            direction={isMobile ? 'column' : 'row'}
            height="100%"
            width="100%"
          >
            {homeCardsArray.map((card) => (
              <HomeCard {...card} key={card.title} id={card.id} />
            ))}
          </Stack>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            objectFit: 'cover',
            alignSelf: 'center',
            justifyContent: 'center',
            width: '90%',
            my: 2,
          }}
        >
          <BannerComponent />
        </Box>
      </BicolorBackgroundBox>
    </Box>
  );
};

export default Home;
