import React, { useState } from 'react';
import { Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, Stack, Tooltip } from '@mui/material';
import DoneAll from '@mui/icons-material/DoneAll';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DatesFilter } from '@fingo/lib/components/filters';
import { formatMinWordDate } from '@fingo/lib/helpers';

const NotificationFilters = (
  {
    dates,
    setDates,
    isReadButtonSelected,
    setIsReadButtonSelected,
    markAllAsRead,
  },
) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [datesAnchorEl, setDatesAnchorEl] = useState(null);
  return (
    <>
      <Stack direction="row" alignItems="center">
        <Button
          onClick={(event) => setDatesAnchorEl(event.currentTarget)}
          variant="outlined"
          endIcon={<ExpandMore />}
          size="small"
          id="notifications-filter-dates"
        >
          {formatMinWordDate(dates.dateIssued_Gte)}{' - '}{formatMinWordDate(dates.dateIssued_Lte)}
        </Button>
        <Tooltip title="Filtrar sólo notificaciones no leídas">
          <Button
            variant={isReadButtonSelected ? 'contained' : 'outlined'}
            color={isReadButtonSelected ? 'primary' : 'inherit'}
            onClick={() => setIsReadButtonSelected(!isReadButtonSelected)}
            style={{ marginLeft: '1rem' }}
            size="small"
            id="notifications-filter-unread"
          >
            Mostrar sólo no leídas
          </Button>
        </Tooltip>
        <IconButton
          size="small"
          variant="outlined"
          color="default"
          style={{ marginLeft: 8 }}
          onClick={(event) => setAnchorElement(event.currentTarget)}
          id="notifications-more-button"
        >
          <MoreHoriz />
        </IconButton>
      </Stack>
      <DatesFilter
        anchorEl={datesAnchorEl}
        close={() => setDatesAnchorEl(null)}
        dates={dates}
        setDates={setDates}
      />
      <Menu
        id="user-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem
            to="/notifications"
            button
            onClick={() => { markAllAsRead(); setAnchorElement(null); }}
            id="notifications-more-read"
          >
            <ListItemIcon>
              <DoneAll />
            </ListItemIcon>
            <ListItemText primary="Marcar todas como leídas" />
          </ListItem>
          <ListItem
            to="/app/profile/settings"
            button
            component={Link}
            id="notifications-more-settings"
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Configuración de notificaciones" />
          </ListItem>
        </List>
      </Menu>
    </>
  );
};

NotificationFilters.propTypes = {
  dates: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    dateIssued_Gte: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    dateIssued_Lte: PropTypes.object,
  }).isRequired,
  setDates: PropTypes.func.isRequired,
  isReadButtonSelected: PropTypes.bool.isRequired,
  setIsReadButtonSelected: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
};

export default NotificationFilters;
