import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import SiiDialog from '@fingo/lib/components/dialogs/SiiDialog';
import { openSiiForm, openSiiFormWithoutAutoFill } from '@fingo/lib/helpers/sii-credentials-form-toggle';
import { useGoogleFirstInteraction } from '@fingo/lib/hooks';
import useLocalStorageTimer from '@fingo/lib/hooks/useLocalStorageTimer';
import { MailVerificationSnackBar } from '@fingo/lib/components/dialogs';
import { FinishRegisterAutomaticDialog } from '../../components/dialogs';

const PopUpAlertProvider = ({ children }) => {
  const selectedCompany = useSelectedCompany();
  const { pathname } = useLocation();
  const googleFirstInteraction = useGoogleFirstInteraction();
  const { lastTimeStored: lastSiiTimeStored, resetTimer: resetSiiTimer } = useLocalStorageTimer('SII_SHOWN');
  useEffect(() => {
    const notShowPaths = [
      '/',
      '/app',
      '/app/home',
    ];
    if (googleFirstInteraction && moment().diff(lastSiiTimeStored, 'minutes') > 10) {
      openSiiFormWithoutAutoFill();
      resetSiiTimer();
    }
    if (moment().diff(lastSiiTimeStored, 'hours') < 3) return;
    if (notShowPaths.includes(pathname)) return;
    if (!selectedCompany) return;
    if (!selectedCompany.hasLendingAcceptedterms) return;
    if (!selectedCompany.hasSiiCredentials) openSiiForm(selectedCompany);
  }, [selectedCompany, pathname]);
  return (
    <>
      <SiiDialog closeCallback={resetSiiTimer} />
      <FinishRegisterAutomaticDialog />
      <MailVerificationSnackBar />
      {children}
    </>
  );
};

PopUpAlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PopUpAlertProvider;
