import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { PaperHeader } from '@fingo/lib/components/headers';
import { GET_USER } from '@fingo/lib/graphql';
import { Grid } from '@mui/material';
import { useTutorialHandler } from '@fingo/lib/hooks';
import { SophiaHead, Graphs } from '../../components/sophia';
import { SophiaTourGuide } from '../../components/joyRides';

const Tracker = () => {
  const TUTORIAL_CODE = 'sophia_tutorial';
  const [isReady, setIsReady] = useState(false);
  const { data: { getUser: { userseentutorialSet, ...user } } } = useQuery(GET_USER);
  const [runGuide, toggleRunGuide] = useTutorialHandler(TUTORIAL_CODE, isReady);
  return (
    <Grid container id="name-container" pb={3}>
      <PaperHeader
        type="sophia"
        viewTitle={user.selectedCompany ? user.selectedCompany.name : user.completeName}
        openJoyride={toggleRunGuide}
      />
      <SophiaHead setIsReady={setIsReady} />
      <Graphs />
      <SophiaTourGuide run={runGuide} setRun={toggleRunGuide} />
    </Grid>
  );
};

export default Tracker;
